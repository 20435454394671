
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

//import { AppModule } from './app/app.modules';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


/*

import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
mapboxgl.accessToken = 'pk.eyJ1IjoiZWRnYXJvc28iLCJhIjoiY2wweTM4NTFhMGR5eDNqcGptanV5aHp4aSJ9.KLCQka6moBKdKra6Xw1nZA';


if (!navigator.geolocation) {
  alert ('Navegador no soporta la Geolocation');  
  throw new Error('Navegador no soporta la Geolocation');
}
*/

if (environment.production) { 
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
