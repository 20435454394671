// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mapBoxToken: 'pk.eyJ1IjoiZWRnYXJvc28iLCJhIjoiY2wweTM4NTFhMGR5eDNqcGptanV5aHp4aSJ9.KLCQka6moBKdKra6Xw1nZA',
  //urlIp: 'http://192.168.1.2:3001'
    urlIp: 'Http://181.129.59.85:3001'  // SERVIDOR DE TAXIS MEDELLIN

    //urlIp: 'Http://181.129.59.85:3001'  // SERVIDOR DE TAXIS MEDELLIN
//  Http://181.129.59.85:3001/api/servicios













};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
