import { Component, Input, OnInit } from '@angular/core';



//import { PorteriasService } from 'src/app/services/porterias.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  //@Input() cedulaportero = '';

  cedulaportero: string;

  menuPorteria: boolean;
  menuListaServicios: boolean;
  menuEmi: boolean;
  menuSuspendido: boolean;
  menuVehiculos: boolean;
  menuViajesVales: boolean;
  menuListadoViajesVales: boolean;
  menuListadoServiciosACumplir: boolean;
  menuUsuarioEmi: boolean;
  menuListadoUsuariosViajan: boolean;
  menuRecargas: boolean;
  menuTaxAntioquia: boolean;
  menuSolicitudPagos: boolean;

  codigoporteria = '';
  opcionesLogin: any[] = [];

  constructor() { }

  ngOnInit() {

    this.menuPorteria = false;
    this.menuListaServicios = false;
    this.menuEmi = false;
    this.menuVehiculos = false;
    this.menuViajesVales = false;
    this.menuListadoViajesVales = false;
    this.menuListadoServiciosACumplir = false;
    this.menuUsuarioEmi = false;
    this.menuListadoUsuariosViajan = false;
    this.menuRecargas = false;
    this.menuTaxAntioquia = false;
    this.menuSolicitudPagos = false;



    this.codigoporteria = localStorage.getItem('codigoporteria');

    if (this.codigoporteria == null) {
      this.codigoporteria = '';
    }

    console.log(' --- codigo porteria --- ', localStorage.getItem('codigoporteria'));
    console.log(' --- telefonoPorteria--- ', localStorage.getItem('telefonoPorteria'));
    console.log(' --- nombrePorteria --- ', localStorage.getItem('nombrePorteria'));
    console.log(' --- cedulaPortero--- ', localStorage.getItem('cedulaPortero'));
    console.log(' --- nombrePortero --- ', localStorage.getItem('nombrePortero'));
    console.log(' --- menuPorteria --- ', localStorage.getItem('menuPorteria'));
    console.log(' --- menuListaServicios--- ', localStorage.getItem('menuListaServicios'));
    console.log(' --- menuVehiculos --- ', localStorage.getItem('menuVehiculos'));
    console.log(' --- menuEmi --- ', localStorage.getItem('menuEmi'));
    console.log(' --- menuSuspendido --- ', localStorage.getItem('menuSuspendido'));
    console.log(' --- menuViajesVales --- ', localStorage.getItem('menuViajesVales'));
    console.log(' --- menuListadoViajesVales --- ', localStorage.getItem('menuListadoViajesVales'));
    console.log(' --- menuListadoServiciosACumplir --- ', localStorage.getItem('menuListadoServiciosACumplir'));
    console.log(' --- menuUsuarioEmi --- ', localStorage.getItem('menuUsuarioEmi'));
    console.log(' --- menuListadoUsuariosViajan --- ', localStorage.getItem('menuListadoUsuariosViajan'));
    console.log(' --- menuRecargas --- ', localStorage.getItem('menuRecargas'));
    console.log(' --- movil --- ', localStorage.getItem('movil'));
    console.log(' --- menuTaxAntioquia  --- ', localStorage.getItem('menuTaxAntioquia'));
    console.log(' --- menuSolicitudPagos  --- ', localStorage.getItem('menuSolicitudPagos'));
 






    if (this.codigoporteria.length > 0) {


      // ----------------------   menuTaxAntioquia --------------------------------------
      if (localStorage.getItem('menuTaxAntioquia') === 'true') {
        this.menuTaxAntioquia = true;
        // consultar para ver si hay viajes del movil para no mostrarele la pantalla
        console.log('menu de menuTaxAntioquia ', this.menuTaxAntioquia);
      } else {


        // -----  porteria
        if (localStorage.getItem('menuPorteria') === 'true') {
          this.menuPorteria = true;
          console.log('menu de porteria ', this.menuPorteria);
        };

        // -----  menuListaServicios
        if (localStorage.getItem('menuListaServicios') === 'true') {
          this.menuListaServicios = true;
          this.menuListadoServiciosACumplir = true;

          console.log('menu de menuListaServicios ', this.menuListaServicios);
        };

        // -----  menuVehiculos
        if (localStorage.getItem('menuVehiculos') === 'true') {
          this.menuVehiculos = true;
          console.log('menu de menuVehiculos ', this.menuVehiculos);
        };

        // -----  menuEmi
        if (localStorage.getItem('menuEmi') === 'true') {
          this.menuEmi = true;
          console.log('menu de menuEmi ', this.menuEmi);
        };

        // -----  menuSuspendido
        if (localStorage.getItem('menuSuspendido') === 'true') {
          this.menuSuspendido = true;
          console.log('menu de menuSuspendido ', this.menuSuspendido);
        };

        // -----  menuViajesVales
        if (localStorage.getItem('menuViajesVales') === 'true') {
          this.menuViajesVales = true;



          console.log('------------- menu de dokaaaaaaaaaaaaaaaaaaaa  menuViajesVales ', this.menuViajesVales);
        };

        // -----  menuListadoViajesVales
        if (localStorage.getItem('menuListadoViajesVales') === 'true') {
          this.menuListadoViajesVales = true;
          // consultar para ver si hay viajes del movil para no mostrarele la pantalla
          console.log('menu de menuListadoViajesVales ', this.menuListadoViajesVales);
        };

        /*
        
                   // -----  menuListadoServiciosACumplir
              if (localStorage.getItem('menuListadoServiciosACumplir') === 'true') {
                this.menuListadoServiciosACumplir = true;
                // consultar para ver si hay viajes del movil para no mostrarele la pantalla
                console.log('menu de menuListadoServiciosACumplir ', this.menuListadoServiciosACumplir);
              };
        */
        // ----------------------   menuUsuarioEmi --------------------------------------
        if (localStorage.getItem('menuUsuarioEmi') === 'true') {
          this.menuUsuarioEmi = true;
          // consultar para ver si hay viajes del movil para no mostrarele la pantalla
          console.log('menu de menuUsuarioEmi ', this.menuUsuarioEmi);
        };

        // ----------------------   menuListadoUsuariosViajan --------------------------------------
        if (localStorage.getItem('menuListadoUsuariosViajan') === 'true') {
          this.menuListadoUsuariosViajan = true;
          // consultar para ver si hay viajes del movil para no mostrarele la pantalla
          console.log('menu de menuListadoUsuariosViajan ', this.menuListadoUsuariosViajan);
        };

        // ----------------------   menuRecargas --------------------------------------
        if (localStorage.getItem('menuRecargas') === 'true') {
          this.menuRecargas = true;
          // consultar para ver si hay viajes del movil para no mostrarele la pantalla
          console.log('menu de menuRecargas ', this.menuRecargas);
        };

        // ----------------------   menuSolicitudPagos --------------------------------------
        if (localStorage.getItem('menuSolicitudPagos') === 'true') {
          this.menuSolicitudPagos = true;
          // consultar para ver si hay viajes del movil para no mostrarele la pantalla
          console.log('menu de menuSolicitudPagos ', this.menuSolicitudPagos);
        };


      }

    }
  }

}
