import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, mdTransitionAnimation } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { NgCalendarModule } from 'ionic2-calendar';
import { MapComponent } from '@core/components/map/map.component';
import { environment } from '@env/environment.prod';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { MatTableExporterModule } from 'mat-table-exporter'
import { MatRadioModule } from '@angular/material/radio';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';


import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';

import { NgxMaskModule } from 'ngx-mask';


import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemoveDecimalPipe } from './remove-decimal.pipe';


/*
import  AndroidPermissions  from '@ionic-native/android-permissions/ngx';
import  LocationAccuracy  from '@ionic-native/location-accuracy/ngx';
import  Geolocation  from '@ionic-native/geolocation/ngx';

*/



// const config: SocketIoConfig = { url: 'Http://181.129.59.85:3001', options: {} };
//const config: SocketIoConfig = { url: 'http://3.144.137.116:3001', options: {} };

// si tengo el server en local para que me funcione el celular debo poner la direccion de
// la tarjeta
//const config: SocketIoConfig = { url: 'http://192.168.1.6:3001', options: {} }; // funciona
//const config: SocketIoConfig = { url: 'Http://181.129.59.85:3001', options: {} }; // para ensayarlo local


//url = localStorage.getItem('cedulaPortero');
//const urlIp = localStorage.getItem('urlIp');

const urlIp = environment.urlIp;


//const config: SocketIoConfig = { url: 'Http://181.129.59.85:3001', options: {} }; // para ensayarlo local
const config: SocketIoConfig = { url: urlIp, options: {} }; // para ensayarlo local



@NgModule({
  declarations: [AppComponent, MapComponent, RemoveDecimalPipe],
  entryComponents: [],
  imports: [

    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SocketIoModule.forRoot(config),
    HttpClientModule,
    ComponentsModule,
    NgCalendarModule,
    ShowHidePasswordModule,
    NgxMaskIonicModule.forRoot(),
    MatTableExporterModule,
    MatRadioModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule.forRoot(),

    // Otros módulos importados
 
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,

 
 


  ],
  providers: [
    NativeAudio,
    /*
     Geolocation,
     LocationAccuracy,
     */

    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    }],
  bootstrap: [AppComponent],
})
export class AppModule { }
