import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDecimal'
})
export class RemoveDecimalPipe implements PipeTransform {

  transform(value: any): any {
    if (typeof value !== 'number') {
      return value;
    }
    return Math.floor(value);
  }

}
