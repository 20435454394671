import { Component, Input, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
 

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  subscribe: any;
  constructor(
    public platform: Platform,
    private alertController: AlertController,

  ) {
    //lo quite porque mo borra las coordenadas del mapa localStorage.clear();

    this.subscribe = this.platform.backButton.subscribeWithPriority(666666, () => {
      if (this.constructor.name === '/home') {
        this.presentAlertConfirm('¿Desea siar de la app ');
      }
    });
  }


  //------------------------------ ALERTA CONFIRMAR------------------------------------------*/
  async presentAlertConfirm(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm!',
      message,
      buttons: [
        {
          text: 'Si',
          id: 'confirm-button',
          handler: () => {
            navigator['app'].exit();
          }
        }, {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }

      ]
    });

    await alert.present();
  }


}
