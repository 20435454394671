import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'listaservicios',
    loadChildren: () => import('./listaservicios/listaservicios.module').then(m => m.ListaserviciosPageModule)
  },
  {
    path: 'vehiculos',
    loadChildren: () => import('./vehiculos/vehiculos.module').then(m => m.VehiculosPageModule)
  },
  {
    path: 'porteria',
    loadChildren: () => import('./porteria/porteria.module').then(m => m.PorteriaPageModule)
  },
  {
    path: 'emivales',
    loadChildren: () => import('./emivales/emivales.module').then(m => m.EmivalesPageModule)
  },
  {
    path: 'detalle-vale',
    loadChildren: () => import('./detalle-vale/detalle-vale.module').then(m => m.DetalleValePageModule)
  },
  {
    path: 'suspendermovil',
    loadChildren: () => import('./suspendermovil/suspendermovil.module').then(m => m.SuspendermovilPageModule)
  },
  {
    path: 'viajes-vehiculos',
    loadChildren: () => import('./viajes-vehiculos/viajes-vehiculos.module').then(m => m.ViajesVehiculosPageModule)
  },

  {
    path: 'emi-listado',
    loadChildren: () => import('./emi-listado/emi-listado.module').then(m => m.EmiListadoPageModule)
  },
  {
    path: 'emi-usuario',
    loadChildren: () => import('./emi-usuario/emi-usuario.module').then(m => m.EmiUsuarioPageModule)
  },
  {
    path: 'registro-emi',
    loadChildren: () => import('./registro-emi/registro-emi.module').then(m => m.RegistroEmiPageModule)
  },
  {
    path: 'lista-usuarios-viajan',
    loadChildren: () => import('./lista-usuarios-viajan/lista-usuarios-viajan.module').then(m => m.ListaUsuariosViajanPageModule)
  },
  {
    path: 'recargas',
    loadChildren: () => import('./recargas/recargas.module').then(m => m.RecargasPageModule)
  },
  {
    path: 'emi-serv-cond',
    loadChildren: () => import('./emi-serv-cond/emi-serv-cond.module').then(m => m.EmiServCondPageModule)
  }, {
    path: 'tax-antioquia',
    loadChildren: () => import('./tax-antioquia/tax-antioquia.module').then(m => m.TaxAntioquiaPageModule)
  },
  {
    path: 'pagos-porteria',
    loadChildren: () => import('./pagos-porteria/pagos-porteria.module').then( m => m.PagosPorteriaPageModule)
  },
  {
    path: 'recepcion',
    loadChildren: () => import('./recepcion/recepcion.module').then( m => m.RecepcionPageModule)
  },
  {
    path: 'emi-admon',
    loadChildren: () => import('./emi-admon/emi-admon.module').then( m => m.EmiAdmonPageModule)
  },
  {
    path: 'emi-viajes-asignados',
    loadChildren: () => import('./emi-viajes-asignados/emi-viajes-asignados.module').then( m => m.EmiViajesAsignadosPageModule)
  },
  {
    path: 'linguoo',
    loadChildren: () => import('./linguoo/linguoo.module').then( m => m.LinguooPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
