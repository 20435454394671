export const environment = {
  production: true,
  mapBoxToken: 'pk.eyJ1IjoiZWRnYXJvc28iLCJhIjoiY2wweTM4NTFhMGR5eDNqcGptanV5aHp4aSJ9.KLCQka6moBKdKra6Xw1nZA',
  //urlIp: 'http://192.168.1.2:3001'

  // septiembre 19 de 2023 buenaaaaaaaa urlIp: 'Http://181.129.59.85:3001'  // SERVIDOR DE TAXIS MEDELLIN

  urlIp: 'Http://181.129.59.85:3001'  // SERVIDOR DE TAXIS MEDELLIN
};


